import React, { Component } from "react";
import { Route } from "react-router-dom";

import Mobile from "../../components/Mobile";
import { connect } from "react-redux";
import { getSettings } from "../../services/settings/actions";

import { getAllLanguages } from "../../services/languages/actions";

class App extends Component {
	componentDidMount() {
		this.props.getSettings();
		this.props.getAllLanguages();
	}

	render() {
		return (
			<React.Fragment>
				{window.innerWidth >= 768 ? (
					 <Route exact path="/" render={() => (window.location = "https://lokal-essen.com/")} />
				) : (
					<Mobile languages={this.props.languages} />
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	settings: state.settings.settings,
	user: state.user.user,
	notification_token: state.notification_token.notification_token,
	languages: state.languages.languages,
});

export default connect(
	mapStateToProps,
	{ getSettings, getAllLanguages }
)(App);
