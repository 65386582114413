import React, { Component } from "react";
import { loginUser, registerUser, sendOtp, verifyOtp } from "../../../../services/user/actions";

import BackButton from "../../Elements/BackButton";
import { Redirect } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import Loading from "../../../helpers/loading";

class GuestOrder extends Component {
	constructor() {
		super();
		this.validator = new SimpleReactValidator({
			autoForceUpdate: this,
			messages: {
				required: localStorage.getItem("fieldValidationMsg"),
				string: localStorage.getItem("nameValidationMsg"),
				email: localStorage.getItem("emailValidationMsg"),
				regex: localStorage.getItem("phoneValidationMsg"),
				min: localStorage.getItem("minimumLengthValidationMessage"),
				in: "Die Passwörter stimmen nicht überein.",
			},
		});
	}

	state = {
		loading: false,
		name: "",
		email: "",
		phone: "",
		guestCreated: false,
		otp: "",
		accessToken: "",
		error: false,
		email_phone_already_used: false,
		invalid_otp: false,
		showResendOtp: false,
		countdownStart: false,
		countDownSeconds: 15,
		enSOV: "",
		errorMessage: ""
	};

	static contextTypes = {
		router: () => null,
	};

	componentDidMount() {
		const enSOV = this.props.settings && this.props.settings.find((data) => data.key === "enSOV");
		this.setState({ enSOV: enSOV.value });

		if (
			localStorage.getItem("enableFacebookLogin") === "false" &&
			localStorage.getItem("enableGoogleLogin") === "false"
		) {
			if (document.getElementById("socialLoginDiv")) {
				document.getElementById("socialLoginDiv").classList.add("hidden");
			}
		}

		if (
			localStorage.getItem("enableFacebookLogin") === "true" ||
			localStorage.getItem("enableGoogleLogin") === "true"
		) {
			setTimeout(() => {
				if (this.refs.socialLogin) {
					this.refs.socialLogin.classList.remove("hidden");
				}
				if (this.refs.socialLoginLoader) {
					this.refs.socialLoginLoader.classList.add("hidden");
				}
			}, 0.5 * 1000);
		}
	}

	handleInputChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleRegister = (event) => {
		event.preventDefault();

		if (
			this.validator.fieldValid("name") &&
			this.validator.fieldValid("email") &&
			this.validator.fieldValid("phone")
		) {
			this.setState({ loading: true });
			if (this.state.enSOV === "true") {
				//sending email and phone, first verify if not exists, then send OTP from the server
				this.props.sendOtp(this.state.email, this.state.phone, null).then((response) => {
					if (!response.payload.otp) {
						this.setState({ error: true, errorMessage: response.payload.message });
					}
				});
			} else {
				this.props.registerUser(
					this.state.name,
					this.state.email,
					this.state.phone,
					"SDhw&27dhGWd9WdsanwU27dso",
					JSON.parse(localStorage.getItem("userSetAddress")),
					true
				);
			}
		} else {
			console.log("Validation Failed");
			this.validator.showMessages();
		}
	};

	resendOtp = () => {
		if (this.validator.fieldValid("phone")) {
			this.setState({ countDownSeconds: 15, showResendOtp: false });
			this.props.sendOtp(this.state.email, this.state.phone, null).then((response) => {
				if (!response.payload.otp) {
					this.setState({ error: true });
				}
			});
		}
	};

	handleVerifyOtp = (event) => {
		event.preventDefault();
		console.log("verify otp clicked");
		if (this.validator.fieldValid("otp")) {
			this.setState({ loading: true });
			this.props.verifyOtp(this.state.phone, this.state.otp);
		}
	};

	componentWillReceiveProps(newProps) {
		const { user } = this.props;

		if (user !== newProps.user) {
			this.setState({ loading: false });
		}

		if (newProps.user.success) {
			if (newProps.user.data.default_address !== null) {
				const userSetAddress = {
					lat: newProps.user.data.default_address.latitude,
					lng: newProps.user.data.default_address.longitude,
					address: newProps.user.data.default_address.address,
					house: newProps.user.data.default_address.house,
					tag: newProps.user.data.default_address.tag,
				};
				localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
			}

			// if (localStorage.getItem("fromCartToLogin") === "1") {
			// 	localStorage.removeItem("fromCartToLogin");
			// 	console.log("HERE1");
			// 	//redirect to cart page
			// 	this.context.router.history.push("/cart");
			// }
			// } else {
			// 	//goback
			// 	this.context.router.history.goBack();
			// }
		}

		if (newProps.user.email_phone_already_used) {
			this.setState({ email_phone_already_used: true });
		}
		if (newProps.user.otp) {
			this.setState({ email_phone_already_used: false, error: false });
			//otp sent, hide reg form and show otp form
			document.getElementById("registerForm").classList.add("hidden");
			document.getElementById("socialLoginDiv").classList.add("hidden");
			document.getElementById("phoneFormAfterSocialLogin").classList.add("hidden");
			document.getElementById("otpForm").classList.remove("hidden");

			//start countdown
			this.setState({ countdownStart: true });
			this.handleCountDown();
			this.validator.hideMessages();
		}

		if (newProps.user.valid_otp) {
			this.setState({ invalid_otp: false, error: false, loading: true });
			// register user
			if (this.state.social_login) {
				this.props.loginUser(
					this.state.name,
					this.state.email,
					null,
					this.state.accessToken,
					this.state.phone,
					this.state.provider,
					JSON.parse(localStorage.getItem("userSetAddress"))
				);
			} else {
				this.props.registerUser(
					this.state.name,
					this.state.email,
					this.state.phone,
					"SDhw&27dhGWd9WdsanwU27dso",
					JSON.parse(localStorage.getItem("userSetAddress")),
					true
				);
			}

			console.log("VALID OTP, REG USER NOW");
			// this.setState({ loading: false });
		}

		if (newProps.user.valid_otp === false) {
			console.log("Invalid OTP");
			this.setState({ invalid_otp: true });
		}

		if (!newProps.user) {
			this.setState({ error: true });
		}

		//old user, proceed to login after social login
		if (newProps.user.proceed_login) {
			console.log("From Social : user already exists");
			this.props.loginUser(
				this.state.name,
				this.state.email,
				null,
				this.state.accessToken,
				null,
				this.state.provider,
				JSON.parse(localStorage.getItem("userSetAddress"))
			);
		}

		if (newProps.user.enter_phone_after_social_login) {
			this.validator.hideMessages();
			document.getElementById("registerForm").classList.add("hidden");
			document.getElementById("socialLoginDiv").classList.add("hidden");
			document.getElementById("phoneFormAfterSocialLogin").classList.remove("hidden");
			// populate name & email
			console.log("ask to fill the phone number and send otp process...");
		}
	}

	handleCountDown = () => {
		setTimeout(() => {
			this.setState({ showResendOtp: true });
			clearInterval(this.intervalID);
		}, 15000 + 1000);
		this.intervalID = setInterval(() => {
			console.log("interval going on");
			this.setState({ countDownSeconds: this.state.countDownSeconds - 1 });
		}, 1000);
	};

	componentWillUnmount() {
		//clear countdown
		console.log("Countdown cleared");
		clearInterval(this.intervalID);
	}

	redirectAdressSearch = () => {
		localStorage.setItem("fromCartToGuestToAdress", 1);
		this.context.router.history.push("/search-location");
	};

	render() {
		if (window.innerWidth > 768) {
			return <Redirect to="/" />;
		}
		if (localStorage.getItem("storeColor") === null) {
			return <Redirect to={"/"} />;
		}
		const { user } = this.props;
		if (user.success) {
			localStorage.setItem("LoggedInAsGuest", 1);
			if (localStorage.getItem("fromCartToGuestLogin") === "1") {
				localStorage.removeItem("fromCartToGuestLogin");
				return (
					//redirect to cart page
					<Redirect to={"/cart"} />
				);
			} else {
				return (
					//redirect to account page
					<Redirect to={"/my-account"} />
				);
			}
		}
		return (
			<React.Fragment>
				{this.state.error && (
					<div className="auth-error">
						<div className="error-shake">{this.state.errorMessage !== "" ? this.state.errorMessage : localStorage.getItem("loginErrorMessage")}</div>
					</div>
				)}
				{this.state.email_phone_already_used && (
					<div className="auth-error">
						<div className="error-shake">{localStorage.getItem("emailPhoneAlreadyRegistered")}</div>
					</div>
				)}
				{this.state.invalid_otp && (
					<div className="auth-error">
						<div className="error-shake">{localStorage.getItem("invalidOtpMsg")}</div>
					</div>
				)}
				{this.state.loading && <Loading />}
				<div className="auth-header">
					<div className="input-group">
						<div className="input-group-prepend">
							<BackButton history={this.props.history} />
						</div>
					</div>
					<img
						src="/assets/img/login-header.png"
						className="login-image pull-right mr-15"
						alt="login-header"
					/>
					<div className="login-texts px-15 mt-50 pb-20">
						<span className="login-title">Als Gast fortfahren</span>
						<br />
					</div>
				</div>

				<div className="bg-white">
					<form onSubmit={this.handleRegister} id="registerForm">
						<div className="form-group px-15 pt-30">
							<label className="col-12 edit-address-input-label">
								{localStorage.getItem("loginLoginNameLabel")}{" "}
								{this.validator.message("name", this.state.name, "required|string")}
							</label>
							<div className="col-md-9 pb-5">
								<input
									type="text"
									name="name"
									onChange={this.handleInputChange}
									className="form-control edit-address-input"
								/>
							</div>
							<label className="col-12 edit-address-input-label">
								{localStorage.getItem("loginLoginEmailLabel")}{" "}
								{this.validator.message("email", this.state.email, "required|email")}
							</label>
							<div className="col-md-9 pb-5">
								<input
									type="text"
									name="email"
									onChange={this.handleInputChange}
									className="form-control edit-address-input"
								/>
							</div>
							<label className="col-12 edit-address-input-label">
								{localStorage.getItem("loginLoginPhoneLabel")}{" "}
								{this.validator.message("phone", this.state.phone, [
									"required",
									{ regex: ["^\\+[1-9]\\d{1,14}$"] },
									{ min: ["8"] },
								])}
							</label>
							<div className="col-md-9 pb-5">
								<input
									defaultValue={
										localStorage.getItem("phoneCountryCode") === null
											? ""
											: localStorage.getItem("phoneCountryCode")
									}
									name="phone"
									type="tel"
									onChange={this.handleInputChange}
									className="form-control edit-address-input"
								/>
							</div>
							<label className="col-12 edit-address-input-label">
								Adresse{" "}
							</label>
							<div className="col-md-9 pb-5">
								<input
									defaultValue={
										localStorage.getItem("userSetAddress") === null
											? ""
											: (JSON.parse(localStorage.getItem("userSetAddress")).address)
									}
									name="adress"
									type="text"
									onChange={this.handleInputChange}
									readOnly={true}
									className="form-control edit-address-input"
									style={{ backgroundColor: "transparent"}}
								/>
							</div>
						</div>

						{localStorage.getItem("registrationPolicyMessage") !== "null" && (
						<div
							className="mt-20 mb-20 d-flex align-items-center justify-content-center"
							dangerouslySetInnerHTML={{
								__html: localStorage.getItem("registrationPolicyMessage"),
							}}
						/>
						)}

						{localStorage.getItem("userSetAddress") != null ? (
							<div className="mt-20 px-15 pt-15 button-block">
								<button
									type="submit"
									className="btn btn-main"
									style={{ backgroundColor: localStorage.getItem("storeColor") }}
								>
									Als Gast fortfahren
								</button>
							</div>
						) : (
						<div className="mt-20 px-15 pt-15 button-block">
							<button
								onClick={this.redirectAdressSearch}
								className="btn btn-main"
								style={{ backgroundColor: localStorage.getItem("storeColor") }}
							>
								Adresse festlegen
							</button>
						</div>
						)}
					</form>

					<form onSubmit={this.handleVerifyOtp} id="otpForm" className="hidden">
						<div className="form-group px-15 pt-30">
							<label className="col-12 edit-address-input-label">
								{localStorage.getItem("otpSentMsg")} {this.state.phone}
								{this.validator.message("otp", this.state.otp, "required|numeric|min:4|max:6")}
							</label>
							<div className="col-md-9">
								<input
									name="otp"
									type="tel"
									onChange={this.handleInputChange}
									className="form-control edit-address-input"
									required
								/>
							</div>

							<button
								type="submit"
								className="btn btn-main"
								style={{ backgroundColor: localStorage.getItem("storeColor") }}
							>
								{localStorage.getItem("verifyOtpBtnText")}
							</button>

							<div className="mt-30 mb-10">
								{this.state.showResendOtp && (
									<div className="resend-otp" onClick={this.resendOtp}>
										{localStorage.getItem("resendOtpMsg")} {this.state.phone}
									</div>
								)}

								{this.state.countDownSeconds > 0 && (
									<div className="resend-otp countdown">
										{localStorage.getItem("resendOtpCountdownMsg")} {this.state.countDownSeconds}
									</div>
								)}
							</div>
						</div>
					</form>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user.user,
	settings: state.settings.settings,
});

export default connect(
	mapStateToProps,
	{ registerUser, loginUser, sendOtp, verifyOtp }
)(GuestOrder);
